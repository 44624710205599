table {
    border-collapse: separate;
    
    tr {
        th{
            // border: 1px solid black;
            border-collapse: collapse;
            padding: 5px;
            text-align: left;
            width: 200px;
        }
        td{
            // border: .5px solid black;
            border-collapse: collapse;
            padding: 5px;
            text-align: left;
            
        }
}
}